<template>
  <div class="content">
    <div class="SearchBar">
      <el-button  @click="closeadd" size="mini" class="backbtn">返回</el-button>
      <el-form :inline="true" ref="templateForm" :model="templateForm" :rules="rule">
        <el-row>
          <el-col class="cow">
            <el-form-item label="模板名称" label-width="163px" class="searchItem" prop="tplName">
              <el-input
                  v-model="templateForm.tplName"
                  placeholder="请输入"
                  clearable
                  style="width:434px;height:40px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="cow">
            <el-form-item label="用户分类" label-width="163px" class="searchItem" prop="userType">
              <template>
                <el-radio-group v-model="templateForm.userType" style="width:800px">
                  <el-radio v-for="(item,index) in options" :key="index" :label="item.id" style="margin-bottom:10px">{{item.name}}</el-radio>
                </el-radio-group>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="cow">
            <el-form-item label="启用状态" label-width="163px" class="searchItem" prop="generalStatus">
              <template>
                <el-radio-group v-model="templateForm.generalStatus">
                  <el-radio  :label="1">启用</el-radio>
                  <el-radio  :label="0">禁用</el-radio>
                </el-radio-group>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="cow">
            <el-form-item label="采集项" label-width="163px" class="searchItem" prop="itemIds">
              <!-- <template> -->
              <div class="checkDiv">
                <div  class="checkout_box">
                  <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                  <div>({{templateForm.itemIds.length}}/{{length.length-citiesList.length}})</div>
                </div>
                <el-tree
                    ref="channelTree"
                    :data="citiesList"
                    show-checkbox
                    node-key="id"
                    :default-expanded-keys="defaultExpandedKeys"
                    :default-checked-keys="defaultCheckedKeys"
                    :props="{ children: 'children',label: 'name'}"
                    @check="handleCheck"
                    >
                </el-tree>
              </div>
              <!-- </template> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </div>
    <div class="btndiv">
      <el-button  @click="closeadd">取消</el-button>
      <el-button  @click="addUpdata" type="primary" style="margin: 10px 50px 41px 20px;">确定</el-button>
    </div>
  </div>
</template>
<script>
import {
  selectDictListByPid
} from "@/RequestPort/business/userFile.js";
import {scuTemplateDetails, scuTemplateInsertOrUpdate,findScuItemTreeEcho} from "../../../RequestPort/peopleUser/people";
export default {
  data() {
    return {
      defaultExpandedKeys:[],
      defaultCheckedKeys:[],
      length:[],
      // 用户分类
      options:[],
      // 对象
      templateForm:{
        tplName:'',
        userType:'',
        generalStatus:1,
        itemIds: [],
      },
      // 全选
      checkAll: false,
      // 所有采集项
      citiesList: [],
      rule:{
        tplName: {
          required: true,
          message: "请填写模板名称",
          trigger: "change"
        },
        userType: {
          required: true,
          message: "请选择用户分类",
          trigger: "change",
        },
        generalStatus: {
          required: true,
          message: "请选择启用状态",
          trigger: "change",
        },
        itemIds: {
          required: true,
          message: "请选择采集项",
          trigger: "change",
        },
      }
    }
  },
  mounted() {
    // 获取用户分类
    selectDictListByPid({pid: 732}).then(res => {
      this.options = res.data
    })
    if(this.$route.query.id){
      this.particulars(this.$route.query.id)
    }else {
      // 所有采集项
      findScuItemTreeEcho().then(res => {
        this.citiesList = res.data.data
        this.citiesList.forEach(el => {
          if(el.children){
            el.children.forEach(item=>{
              this.length.push(item.id)
            })
          }
          this.length.push(el.id)
        })
      })
    }
  },
  methods: {
    handleCheck(data,checked){
      let arr = JSON.parse(JSON.stringify(checked))
      let arr3 = []
      let arr4 = []
      this.citiesList.forEach(item=>{
        if(item.children){
          arr3.push(item.id)
          item.children.forEach(i=>{
            arr4.push(i)
          })
        }else{
          arr4.push(item)
        }
      })
      let  arr1 = arr.checkedKeys
      let list = arr1.filter(items => {
        if (!arr3.includes(items)) return items;
      })
      let arr5 = []
      arr4.forEach(item=>{
        list.forEach(k=>{
          if(item.id ===k){
            arr5.push(item.trueId)
          }
        })
      })
      this.templateForm.itemIds = arr5
      // if(checked){//选中，如果有子项，直接选中子。
      //   if(data.children){
      //     data.children.forEach(item=>{
      //       if(this.templateForm.itemIds.indexOf(item.trueId)==-1){
      //         this.templateForm.itemIds.push(item.trueId)
      //       }
      //     })
      //   }else{//选中，如果没有子项，直接选中。
      //     if(this.templateForm.itemIds.indexOf(data.trueId)==-1){
      //       this.templateForm.itemIds.push(data.trueId)
      //     }
      //   }
      // }else { //取消选中
      //   if(data.children){//如果有子，循环删除
      //     data.children.forEach(item=>{
      //       if(this.templateForm.itemIds.indexOf(item.trueId)>-1){
      //         this.templateForm.itemIds.splice(this.templateForm.itemIds.indexOf(item.trueId),1)
      //       }
      //     })
      //   }else{//选中，如果没有子项，直接选中。
      //     if(this.templateForm.itemIds.indexOf(data.trueId)>-1){
      //       this.templateForm.itemIds.splice(this.templateForm.itemIds.indexOf(data.trueId),1)
      //     }
      //   }
      // }
    },
    //返回
    closeadd() {
      // this.$router.go(-1)
      this.$router.push({ path: '/home/checkDict', query: { first: 2, screcd: 4 } })
    },
    /**
     * 获取模板详细信息
     */
    particulars(id) {
      scuTemplateDetails({id}).then(res=>{

       this.templateForm.id = res.data.id
       this.templateForm.tplName = res.data.tplName + ''
       this.templateForm.generalStatus = res.data.generalStatus
       this.templateForm.userType = res.data.userType+''
      })
      findScuItemTreeEcho({scuTplId:id}).then(res=>{
        this.citiesList = res.data.data
        let arr = []
        this.citiesList.forEach(el => {
            if(el.children){
              el.children.forEach(son=>{
                this.length.push(son.id)
                res.data.defaultCheckedKeys.forEach(item=>{
                  if(item==son.id){
                    arr.push(son.trueId)
                  }
                })
              })
            }else{
              res.data.defaultCheckedKeys.forEach(item=>{
                if(item==el.id){
                  arr.push(el.trueId)
                }
              })
            }
          this.length.push(el.id)
        })
        this.templateForm.itemIds = arr
        this.defaultCheckedKeys = res.data.defaultCheckedKeys
        this.defaultExpandedKeys = res.data.defaultExpandedKeys
      })
    },
    /**
     * 全选
     */
    handleCheckAllChange(val) {
      if(val){
        this.defaultCheckedKeys = []
        this.citiesList.forEach(el => {
          if(el.children){
            el.children.forEach(item=>{
              this.defaultCheckedKeys.push(item.trueId)
            })
          }else {
            this.defaultCheckedKeys.push(el.trueId)
          }
        })
          this.$refs.channelTree.setCheckedNodes(this.citiesList)
      }else {
        this.defaultCheckedKeys = []
        this.$refs.channelTree.setCheckedNodes([])
      }
      this.templateForm.itemIds = this.defaultCheckedKeys;
    },
    /**
     * 确认/编辑
     */
    addUpdata(){
      this.$refs["templateForm"].validate((valid) => {
        if (valid) {
          scuTemplateInsertOrUpdate(this.templateForm).then(res => {
            this.$message.success(res.data);
            this.closeadd()
          })
        }else {
          this.$message.error('有数据未填写，请仔细核对');
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.searchItem {
  margin-bottom: 34px;
}
.label {
  color: #909399;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: calc(100vh - 250px);
  overflow: auto;
}
// 复选框顶部内容
.checkout_box{
  border-bottom: 1px solid rgb(220, 223, 230);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
// 复选框换行
.el-checkbox{
  display: block;
}
.table2{
  width: 1570px;
  overflow: hidden;
  border: 1px solid #EBEBEB;
  tr{
    height: 50px;
  }
  td{
    border: 1px solid #EBEBEB;
    padding: 5px;
  }
  th{
    padding-top: 12px;
    border: 1px solid #EBEBEB;
    background-color: #F9FAFE;
  }
}
.addbtn{
  width: 92px;
  height: 38px;
  float: right;
  margin-right: -40px;
}
.backbtn{
  margin:30px 0px 40px 30px;
  width: 82px;
  height: 36px;
}
.btndiv{
  margin-left: 163px;
}
.content {
  background: #f3f4f8;;
  width: 100%;
  border-radius: 6px;
  overflow:auto;

}
.delbtn{
  float: right;
  margin-right: 21px;
}
.checkDiv{
  padding: 2%;
  border: 1px solid #DCDFE6;
  width: 500px;
}
</style>
